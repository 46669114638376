$enable-cssgrid: true;
$enable-rounded: false;
$primary: #000;

// Import all of Bootstrap's CSS
//@import "bootstrap/scss/bootstrap";
@import 'node_modules/bootstrap/scss/bootstrap';

body {
  &:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgb(255,255,255);
    background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(0,0,0,1) 100%);
    z-index: 10;
    height:10%;
    @include media-breakpoint-up(md) {
      height:15%;
    }
  }
}

.action-buttons {
  z-index: 30;
  @include media-breakpoint-up(md) {
    position: fixed;
  }
  .btn-group {
    width: 100%;
    @include media-breakpoint-up(md) {
      width: auto;
    }
  }
}

.next {
  @extend .bg-primary;
  @extend .text-white;
}

.video-container {
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-image:
            radial-gradient(circle at center, rgba($dark, 0.2) 2px, transparent 2px),
            radial-gradient(circle at center, rgba($dark, 0.2) 2px, transparent 2px);
    background-size: 8px 8px;
    backdrop-filter: blur(2px);
    z-index: 2; /* Stellt sicher, dass das Pixelnetz über dem Video liegt */
  }
}

.date-wrap {
  position: relative;
  display: flex;
  flex-direction: column;
  @include media-breakpoint-up(md) {
    flex-direction: row;
    justify-content: start;
  }
  &:not(:last-child) {
    margin-bottom: 3px;
  }
}

.date-wrap > div {
  position: relative;
  z-index: 4;
  color: $white;
  //flex: 1 0 auto;
  text-align: center;
  display: flex;
  justify-content: center;
  @include media-breakpoint-up(md) {
    justify-content: start;
  }
  &:nth-child(1) {
    @include media-breakpoint-up(md) {
      flex: 0 1 15%;
    }
  }
  &:nth-child(2) {
    @include media-breakpoint-up(md) {
      flex: 0 1 30%;
    }
  }
  &:nth-child(3) {
    @include media-breakpoint-up(md) {
      flex: 1 0 auto;
      justify-content: end;
    }
  }
}

.date-wrap::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.1); /* Weißer Hintergrund mit 10% Deckkraft */
  backdrop-filter: blur(10px); /* Haupt-Blur-Effekt */
  z-index: 3; /* Stellt sicher, dass der Blur-Effekt über dem Video liegt */
}

.content {
  position: relative;
  z-index: 2; /* Stellt sicher, dass der Inhalt über dem Blur-Effekt liegt */
}

.event-wraps {

  &:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgb(255,255,255);
    background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(0,0,0,1) 100%);
    z-index: 10;
    height:30%;
  }
}

#events-list,
#eventsContainer{
  width: 100%;
  height: 60vh;
  overflow-y: scroll;
  position: relative;
}

iframe {
  position: absolute;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -100;
  //background: url('pfad/zum/backup-bild.jpg') no-repeat;
  background-size: cover;
}
